.schedule-container {
	background-color: #ffffff;
	height: 100%;
	min-height: 100vh;
	padding: 40px 20px;
	font-family: Georgia, "Times New Roman", Times, serif;
}

.booking-selection-container {
	background-color: #ffffff;
	padding: 20px;
	border-radius: 10px;
	max-width: 800px;
	margin: 0 auto;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
	.schedule-container {
		padding: 20px 10px;
	}
}
